'use client'

import ErrorMessage from './error-message'

export default function GlobalError({
  reset,
  error,
}: {
  reset: () => void
  error: Error
}) {
  return (
    <html>
      <body>
        <ErrorMessage reset={reset} error={error} />
      </body>
    </html>
  )
}
